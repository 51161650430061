* {
    font-family: 'Montserrat', sans-serif;
}

.btn:hover {
    color: #6b2a7d !important;
    background-color: white !important;
    box-shadow: 1px 1px 20px 0 rgb(106, 41, 124);
}

.btn {
    border-radius: 100px;
    background-color: #6b2a7d;
    color: white;
    margin: 3px;
}

.btn_black:hover {
    color: black !important;
    background-color: white !important;
    box-shadow: 1px 1px 20px 0 rgb(0, 0, 0);
}

.btn_black {
    border-radius: 100px;
    background-color: black;
    color: white;
    margin: 3px;
}

.forgot_link {
    text-decoration: none;
    float: right;
    color: rgb(43, 43, 43);
    margin-bottom: 20px;
}

.form-control:focus {
    box-shadow: none !important;
    border-color: none !important;
}

.danger {
    color: red;
}

.btntext {
    display: inline-block;
    padding-left: 10px;
}

.icon_header {
    font-size: 25px;
    padding-right: 15px;
}

.link_text {
    display: inline-block;
    padding-left: 15px;
}

@media screen and (max-width: 767px) {
    .btntext {
        display: none;
    }

    .icon_header {
        font-size: 25px;
        padding-right: 0px;
    }

    .link_text {
        display: none;
    }
}

.custom-file-label::after {
    content: "What's up?";
}

.hkULGy {
    display: none !important;
}

#msdoc-renderer {
    overflow-y: hidden !important;
}

/* Custom styles for scrollbar */

#proxy-renderer {
    overflow-y: auto !important;
    /* background-color: #f1f1f1; */

}

/* Webkit browsers (Chrome, Safari) */
#proxy-renderer::-webkit-scrollbar,
#proxy-renderer #pdf-renderer::-webkit-scrollbar {
    width: 12px !important;

}

#proxy-renderer::-webkit-scrollbar-thumb,
#proxy-renderer #pdf-renderer::-webkit-scrollbar-thumb {
    background-color: #888 !important;
    border-radius: 6px !important;
}

#proxy-renderer::-webkit-scrollbar-track,
#proxy-renderer #pdf-renderer::-webkit-scrollbar-track{
    background-color: #f1f1f1 !important;
    border-radius: 6px !important;
}

/* Firefox */
#proxy-renderer {
    scrollbar-width: thin !important;
}

#proxy-renderer::-moz-scrollbar-thumb,
#proxy-renderer #pdf-renderer::-moz-scrollbar-thumb {
    background-color: #888 !important;
    /* Change thumb color */
    border-radius: 6px !important;
    /* Round the corners of the thumb */
}

#proxy-renderer::-moz-scrollbar-track,
#proxy-renderer #pdf-renderer::-moz-scrollbar-track {
    background-color: #f1f1f1 !important;
    /* Change track color */
    border-radius: 6px !important;
}

.modal-header {
    padding: 10px !important;
    /* background-color: rgb(31, 30, 30); */
}

.icon-wrapper {
    display: flex;
    align-items: center;
}

#downloadBtn,
#closeBtn {
    font-size: 34px;
    cursor: pointer;
    color: rgb(107, 106, 106);

    padding: 3px;
}

#downloadBtn:hover,
#closeBtn:hover {
    color: black;
    background-color: #eee;
    border-radius: 5px;
}

/* PDF download button  */
#pdf-download {
    display: none;
}

#pdf-controls {
    background-color: rgb(247, 247, 247);
}

#image-renderer {
    background-image: none;
    /* background-color: whitesmoke; */
}

#image-img {
    max-width: 90%;
    max-height: 90%;
}